// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"web-omni-ekonomi-b5d2b25756cb2d7ebf1ac87d0d798d878b999249"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";
import { browserTracingIntegration } from "@sentry/browser";
import { replayIntegration } from "@sentry/nextjs";

Sentry.init({
  enabled: true,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  tracesSampleRate: 0.05,
  sampleRate: 0.5,
  debug: false,
  replaysOnErrorSampleRate: 0.25,
  replaysSessionSampleRate: 0.005,
  integrations: [
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    browserTracingIntegration({
      enableInp: true,
    }),
  ],
  beforeSendTransaction: (transaction) => {
    // Ignore crawlers
    if (transaction.contexts?.device?.family === "Spider") {
      return null;
    }
    return transaction;
  },
});
